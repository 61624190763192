import React from 'react'
import { Link } from 'gatsby'
import './VirtualitySection.scss'
import loadable from '@loadable/component'
import Marked from 'react-markdown'
const Fade = loadable(() => import('../transition/Fade'))

const VirtualitySection = ({ data, contactDescription, contactImage }) => {
  return (
    <div className="real-time-container" id="common-pl">
      <div className="virtual-reality-container d-lg-flex justify-content-between">
        <div className="title-container first-flex-column">
          <p className="sub-title">MORE ABOUT</p>
          <Fade timeout={500}>
            <h2>{data.title}</h2>
          </Fade>
          <Fade timeout={1000}>
            <p
              className="title-dec"
              dangerouslySetInnerHTML={{
                __html: data.description.replace(/(?:\r\n|\r|\n)/g, ' <br> ')
              }}
            />
          </Fade>
        </div>
        <div className="img-container align-self-lg-start second-flex-column">
          <Fade timeout={1000}>
            <img
              src={data.desktopImage}
              alt="Desktop"
              className="real-time-img big-img"
            />
            <img
              src={data.mobileImage}
              alt="Mobile"
              className="real-time-img small-img"
            />
          </Fade>
        </div>
        <div className="red-angle-container d-none d-lg-block">
          <img
            src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614154622/menu-angle_hdhmid.svg"
            alt="menu angle"
            className="menu-angle"
          />
        </div>
      </div>

      <div className="contact-us-container" id="common-pr">
        <div className="contact-us-content">
          <div className="title-container">
            <p className="sub-title">GET</p>
            <Fade timeout={500}>
              <h2>In touch</h2>
            </Fade>
            <Fade timeout={1000}>
              <p className="title-dec">
                <Marked parserOptions={{ commonmark: true}} source={contactDescription} />
              </p>
            </Fade>
            <div className="text-center text-lg-left">
              <Link className="project-btn" to="/contact">
                Contact Us
              </Link>
            </div>
          </div>
          <div className="img-container">
            <Fade timeout={1000}>
              <img
                src={contactImage}
                className="get-in-touch-img"
                alt="Contact"
              />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VirtualitySection
